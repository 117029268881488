body {
  font-family: 'Poppins', sans-serif;
}


/* sovrascrivo la dimensione massima del container bootstrap 5, mettendo questa definizione all'inizio del file css */
/* non vado a modificare le classi successive, ma sovrascrivo solo la dimensione massima del container */

@media (min-width: 1400px) {
  .container {
      max-width: 1140px;
  }
}

a:hover {
  text-decoration: none !important;
}

.header-navbar .dropdown-menu.dropdown-menu-end {
  transform: translate3d(-94px, 55px, 0px) !important;
}

.header-navbar {
  background-color: white;
  padding-bottom: 6px;
  padding-top: 6px !important;
  user-select: none;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none !important;
  box-shadow: none !important;
}

.input-group-text {
  height: 32px;
  width: 65px;
  border-color: #cedfec;
  background-color: #f2f6fa;
  border-radius: 0%;
}

.black-link {
  text-decoration: none !important;
  color: black !important;
}

.container-sm {
  max-width: 740px;
}

.hamburger-padding {
  margin-left: -4px !important;
}

.navbar-dropdown {
  margin-top: -19px;
}

.standaloneRegistration-cover {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  height: 100%;
  width: 100%;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.2;
  z-index: -10;
}

.standaloneRegistration-image {
  max-width: 40%;
}

.signin-icon {
  margin-right: 6px;
}

h1 {
  color: '#373d3f' !important;
}

h2 {
  color: '#373d3f' !important;
}

h3 {
  color: '#373d3f' !important;
}

h4 {
  font-weight: 400;
  color: '#373d3f' !important;
}

h5 {
  font-weight: 400;
  color: '#373d3f' !important;
}

h6 {
  font-weight: 400;
  color: '#373d3f' !important;
}

.black-link-with-hover {
  color: #373d3f;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.table-gialla td,
.table-gialla th {
  border: 1px solid #f2b705;
  color: #373d3f;
  background-color: #f2b70540;
}

.table-gialla thead th {
  background-color: #f2b705;
  color: #373d3f;
  vertical-align: bottom;
  border-bottom: 2px solid #f2b705;
}

.table-gialla-bianca td,
.table-gialla-bianca th {
  border: 1px solid #f2b705;
  color: #373d3f;
  background-color: white;
  padding-top: 19px;
  padding-bottom: 10px;
  padding-left: 15px;
}

.table-gialla-bianca thead th {
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 10px;
  background-color: #f2b70540;
  color: #373d3f;
  vertical-align: bottom;
  border-bottom: 2px solid #f2b705;
}

.table-bianca td,
.table-bianca th {
  color: #373d3f;
  background-color: white;
  padding: 18px 0;
}

.table-bianca thead th {
  border: none;
  color: rgba(0, 0, 0, 0.5);
}

.table-gialla-chiaro td,
.table-gialla-chiaro th {
  border: 1px solid #f4cb4e;
  background-color: #f4cb4e3a;
  padding-top: 19px;
  padding-bottom: 10px;
  padding-left: 15px;
}

.table-gialla-chiaro thead th {
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 10px;
  background-color: #f4cb4e6e;
  vertical-align: bottom;
  border-bottom: 2px solid #f4cb4e;
}

.bottone-blu,
.bottone-blu:hover,
.bottone-blu:focus,
.bottone-blu:active {
  background-color: #0583f2;
  border-radius: 10px;
  border: none;
  font-weight: 500;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 21px;
  padding-right: 21px;
}

.bottone-blu-scuro,
.bottone-blu-scuro:hover,
.bottone-blu-scuro:focus,
.bottone-blu-scuro:active {
  background-color: #023e73;
  border-radius: 10px;
  border: none;
  font-weight: 500;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 21px;
  padding-right: 21px;
}

.bottone-disabled,
.bottone-disabled:hover,
.bottone-disabled:focus,
.bottone-disabled:active {
  border-radius: 10px;
  border: none;
  font-weight: 500;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 21px;
  padding-right: 21px;
}

.bottone-blu-outline,
.bottone-blu-outline:hover,
.bottone-blu-outline:focus,
.bottone-blu-outline:active {
  background-color: white;
  border-color: #0583f2;
  border-radius: 10px;
  font-weight: 500;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 21px;
  padding-right: 21px;
  color: #0583f2;
}

.bottone-giallo,
.bottone-giallo:hover,
.bottone-giallo:focus,
.bottone-giallo:active {
  background-color: #f2b705;
  border-radius: 10px;
  border: none;
  font-weight: 500;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 21px;
  padding-right: 21px;
}

.bottone-giallo-outline,
.bottone-giallo-outline:hover,
.bottone-giallo-outline:focus,
.bottone-giallo-outline:active {
  background-color: white;
  border-color: #f2b705;
  border-radius: 10px;
  font-weight: 500;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 21px;
  padding-right: 21px;
  color: #f2b705;
}

.bottone-rosso,
.bottone-rosso:hover,
.bottone-rosso:focus,
.bottone-rosso:active {
  background-color: #f2421b;
  border-radius: 10px;
  border: none;
  font-weight: 500;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 11px;
  padding-right: 11px;
}

.bottone-verde,
.bottone-verde:hover,
.bottone-verde:focus,
.bottone-verde:active {
  background-color: #66d98e;
  border-radius: 10px;
  border: 1px solid #66d98e;
  font-weight: 500;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 21px;
  padding-right: 21px;
}

.bottone-verde-outline,
.bottone-verde-outline:hover,
.bottone-verde-outline:focus,
.bottone-verde-outline:active {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #66d98e;
  font-weight: 600;
  color: #66d98e;
  padding-left: 21px;
  padding-right: 21px;
}

.bottone-rosso-outline,
.bottone-rosso-outline:hover,
.bottone-rosso-outline:focus,
.bottone-rosso-outline:active {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #f2421b;
  font-weight: 600;
  color: #f2421b;
}

.bottone-bianco-blu,
.bottone-bianco-blu:hover,
.bottone-bianco-blu:focus,
.bottone-bianco-blu:active {
  background-color: white;
  border-radius: 10px;
  border: none;
  font-weight: 600;
  color: #0583f2;
}

.bottone-bianco-blu-outline,
.bottone-bianco-blu-outline:hover,
.bottone-bianco-blu-outline:focus,
.bottone-bianco-blu-outline:active {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #0583f2;
  font-weight: 600;
  color: #0583f2;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 21px;
  padding-right: 21px;
}

.bottone-bianco-giallo,
.bottone-bianco-giallo:hover,
.bottone-bianco-giallo:focus,
.bottone-bianco-giallo:active {
  background-color: white;
  border-radius: 10px;
  border: none;
  font-weight: 600;
  color: #f2b705;
}

.scuro-cambio-modalita,
.scuro-cambio-modalita:hover,
.scuro-cambio-modalita:focus,
.scuro-cambio-modalita:active {
  color: white;
  border: 1px solid #0583f2;
  background-color: #0583f2;
}

.chiaro-cambio-modalita,
.chiaro-cambio-modalita:hover,
.chiaro-cambio-modalita:focus,
.chiaro-cambio-modalita:active {
  color: #0583f2;
  border: 1px solid #0583f2;
  background-color: white;
}

.font-light {
  font-weight: 300;
  color: #373d3f;
}

.font-light-bianco {
  font-weight: 300;
  color: white;
}

.font-regular {
  font-weight: 400;
  color: #373d3f;
}

.font-medium {
  font-weight: 500;
  color: #373d3f;
}

.font-bold {
  font-weight: 600;
  color: #373d3f;
}

.font-extra-bold {
  font-weight: 700;
  color: #373d3f;
}

.font-grigio-scuro {
  color: #373d3f;
}

.interlinea-20 {
  line-height: 20px;
}

.interlinea-25 {
  line-height: 25px;
}

.interlinea-30 {
  line-height: 30px;
}

.interlinea-35 {
  line-height: 35px;
}

.testo-blu {
  color: #0583f2;
}

.testo-verde {
  color: #66d98e;
}

.testo-verde-scuro {
  color: #2a593b;
}

.testo-rosso {
  color: #f2421b;
}

.testo-arancione {
  color: #f2b705;
}

.barra-verde {
  background-color: #66d98e;
}

.barra-blu {
  background-color: #0583f2;
}

.card {
  border-radius: 10px;
  border: none;
}

.card-bordo-visibile {
  border-radius: 10px;
  border: 1px solid #e6e6e6;
}

.modal-content {
  border-radius: 10px;
  border: none;
}

.card-appuntamento-home {
  background-color: #cccccc; /* Used if the image is unavailable */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

label {
  color: #373d3f;
}

.swal-modal .swal-text {
  text-align: center;
}

.swal-button--confirm {
  background-color: #0583f2;
  border-radius: 10px;
  border: none;
  font-weight: 500;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 11px;
  padding-right: 11px;
  margin-bottom: 20px;
}

.swal-button--cancel {
  border-radius: 10px;
  border: none;
  font-weight: 500;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 11px;
  padding-right: 11px;
}

@media (min-width: 992px) {
  .container-landing {
    max-width: 1600px;
    padding-right: 100px;
    padding-left: 100px;
  }
}

.link-nav-attivo {
  border-bottom: #66d98e 2px solid;
}

.landing-container-height {
  min-height: 95vh;
}

.footer {
  background-color: #0583f2;
}

.no-text-decoration a {
  text-decoration: none;
}

.font-bold-white {
  font-weight: 600;
  color: white;
}

.titolo-landing-fiscomed {
  font-size: calc(45px + 1.5vw);
  font-weight: 500;
  color: #373d3f;
  margin-top: 30px;
  line-height: calc(35px + 3vw);
}

.titolo1-mobile {
  font-weight: 600;
  color: #373d3f;
  font-size: 3rem;
  line-height: 3.5rem;
}

.titolo-2 {
  font-size: calc(30px + 1.5vw);
  font-weight: 500;
  color: #373d3f;
  margin-top: 30px;
  line-height: calc(40px + 1.5vw);
}

.titolo2-mobile {
  font-size: 32px;
  font-weight: 600;
  color: #373d3f;
}

.titolo-3 {
  font-size: calc(20px + 1.5vw);
  color: #373d3f;
  line-height: calc(15px + 3vw);
  font-weight: 500;
}

.titolo3-mobile {
  font-size: 25px;
  font-weight: 600;
  color: #373d3f;
}

.etichetta {
  border-bottom: #f2b705 3px solid;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 1.1vw;
  font-weight: 500;
}

.etichetta-mobile {
  border-bottom: #f2b705 3px solid;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  font-weight: 600;
}

.testo-landing-fiscomed-margini {
  font-size: 20px;
  font-weight: 400;
  color: #373d3f;
  line-height: calc(20px + 1vw);
  margin-top: calc(5px + 2.8vw);
  margin-bottom: calc(10px + 2.8vw);
}

.testo-landing-fiscomed {
  font-size: 20px;
  font-weight: 400;
  color: #373d3f;
  line-height: calc(20px + 1vw);
}

.testo-light-fiscomed {
  font-size: 20px;
  font-weight: 300;
  color: #373d3f;
  line-height: calc(20px + 1vw);
}

.header-landing-desktop-fiscomed {
  background-image: url('./immagini/header_dt_fiscomed.webp');
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.header-landing-mobile-fiscomed {
  background-image: url('./immagini/header_mb_fiscomed.webp');
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  background-position: right;
  top: 0;
  right: 0;
  width: 100%;
  height: 30%;
  z-index: -1;
}

.header-landing-desktop-guida {
  background-image: url('./immagini/header_dt_guida.webp');
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  z-index: -1;
  width: 100%;
}

.header-landing-mobile-guida {
  background-image: url('./immagini/header_mb_guida.webp');
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  background-position: right;
  top: 0;
  right: 0;
  width: 100%;
  height: 30%;
  z-index: -1;
}

.header-landing-desktop-prezzi {
  background-image: url('./immagini/header_dt_prezzi.webp');
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110vh;
  z-index: -1;
}

.header-desktop-blog {
  background-image: url('./immagini/header_blog_desktop.webp');
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110vh;
  z-index: -1;
}

.header-mobile-blog {
  background-image: url('./immagini/header_blog_mobile.webp');
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  background-position: right;
  top: 0;
  right: 0;
  width: 100%;
  height: 30%;
  z-index: -1;
}

.header-landing-mobile-prezzi {
  background-image: url('./immagini/header_mb_prezzi.webp');
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  background-position: right;
  top: 0;
  right: 0;
  width: 100%;
  height: 30%;
  z-index: -1;
}

.nuvola {
  z-index: -1;
  position: absolute;
  width: 150px;
}

.nuvola-mobile {
  z-index: -1;
  position: absolute;
  width: 160px;
}

.cerchio-verde {
  z-index: -1;
  position: absolute;
  margin-top: -20px;
  margin-left: -20px;
  width: 150px;
}

.cerchio-verde-mobile {
  z-index: -1;
  position: absolute;
  margin-top: -20px;
  margin-left: -20px;
  width: 110px;
}

.outer-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-contatto {
  border-radius: 5px;
  min-width: 800px;
  background: linear-gradient(#66d98e3a, #0583f23a);
}

.card-contatto-mobile {
  border-radius: 5px;
  width: 100%;
  background: linear-gradient(#66d98e3a, #0583f23a);
}

.card-contatto-prezzi {
  border-radius: 5px;
  min-width: 800px;
  background-color: #0583f226; /* Blu trasparente al 10% */
}

.card-contatto-prezzi-mobile {
  border-radius: 5px;
  max-width: 800px;
  background-color: #0583f226; /* Blu trasparente al 10% */
}

.div-celeste-guida {
  background-color: #0583f226; /* Blu trasparente al 10% */
  border-radius: 8px;
}

.div-verde-guida {
  background-color: #66d98e26; /* Verde trasparente al 10% */
  border-radius: 8px;
}

.div-giallo-guida {
  background-color: #f2b70526; /* Giallo trasparente al 10% */
  border-radius: 8px;
}

.numero-guida {
  height: 130px;
  position: absolute;
  margin-top: -30px;
  margin-left: -40px;
}

.numero-guida-mobile {
  height: 100px;
  position: absolute;
  margin-top: -25px;
  margin-left: -30px;
}

.card-guida {
  border-radius: 25px;
  border: none;
  background-color: white;
  padding: 10px;
  box-shadow: -1px 2px 4px rgba(205, 205, 205, 0.529);
  position: relative;
  width: 100%;
  margin-right: 100px;
}

.card-guida-mobile {
  border-radius: 25px;
  border: none;
  background-color: white;
  padding: 10px;
  box-shadow: -1px 1px 6px rgba(199, 199, 199, 0.529);
  position: relative;
  width: 100%;
}

.cuore-1-guida {
  position: absolute;
  width: 90px;
  margin-top: -30px;
  margin-left: -50px;
}

.smile-newsletter {
  position: absolute;
  font-size: 1.2rem;
  margin-top: -20px;
  margin-left: 250px;
}

.cuore-newsletter {
  position: absolute;
  width: 90px;
  margin-left: 70px;
}

.cuore-2-guida {
  position: absolute;
  width: 90px;
  margin-top: -50px;
  margin-left: 300px;
}

.like-guida {
  position: absolute;
  width: 90px;
  margin-top: -30px;
  margin-left: 300px;
}

.icona-guida {
  position: absolute;
  width: 120px;
  margin-top: -20px;
  margin-left: -45px;
}

.icona-guida-mobile {
  position: absolute;
  width: 80px;
  margin-top: -10px;
  margin-left: -25px;
}

.card-mensile {
  border-radius: 5px;
  border: none;
  background-color: white;
  padding: 40px;
  box-shadow: 2px 2px 10px rgb(217, 217, 217);
  margin-right: 10px;
  width: 400px;
}

.card-mensile-mobile {
  border-radius: 5px;
  border: none;
  background-color: white;
  padding-right: 40px;
  padding-left: 40px;
  padding-bottom: 64px;
  box-shadow: 2px 2px 5px rgba(128, 128, 128, 0.529);
  margin-right: 10px;
  max-width: 400px;
  margin-top: -24px;
}

.card-annuale {
  border-radius: 5px;
  border: none;
  background-color: white;
  padding-right: 40px;
  padding-left: 40px;
  padding-bottom: 64px;
  box-shadow: 2px 2px 10px rgb(217, 217, 217);
  margin-right: 10px;
  width: 400px;
  margin-top: -24px;
}

.card-annuale-mobile {
  border-radius: 5px;
  border: none;
  background-color: white;
  padding-right: 40px;
  padding-left: 40px;
  padding-bottom: 64px;
  box-shadow: 2px 2px 5px rgba(128, 128, 128, 0.529);
  margin-right: 10px;
  max-width: 400px;
  margin-top: -24px;
}

.header-card-annuale {
  border-radius: 5px;
  border: none;
  background-color: #66d98e;
  padding-right: 40px;
  padding-left: 40px;
  width: 400px;
  margin-left: -40px;
  margin-right: -40px;
  margin-bottom: 40px;
  padding-top: 10px;
  padding-bottom: 5px;
}

.header-card-mensile {
  border-radius: 5px;
  border: none;
  background-color: #0583f2;
  padding-right: 40px;
  padding-left: 40px;
  width: 400px;
  margin-left: -40px;
  margin-right: -40px;
  margin-bottom: 40px;
  padding-top: 10px;
  padding-bottom: 5px;
}

.header-card-annuale-mobile {
  border-radius: 5px;
  border: none;
  background-color: #66d98e;
  padding-right: 40px;
  padding-left: 40px;
  max-width: 400px;
  margin-left: -40px;
  margin-right: -40px;
  margin-bottom: 40px;
  padding-top: 10px;
  padding-bottom: 5px;
  height: 30px;
}

.header-card-mensile-mobile {
  border-radius: 5px;
  border: none;
  background-color: #0583f2;
  padding-right: 40px;
  padding-left: 40px;
  max-width: 400px;
  margin-left: -40px;
  margin-right: -40px;
  margin-bottom: 40px;
  padding-top: 10px;
  padding-bottom: 5px;
  height: 30px;
}

.div-gradient-newsletter {
  background: linear-gradient(#66d98e3a, #0583f23a);
  border-radius: 5px;
  padding: 40px;
}

.logo-footer {
  max-width: 180px;
}

.mobile-container {
  padding-left: 25px;
  padding-right: 25px;
}

.margin-mobile-container {
  margin-left: 25px;
  margin-right: 25px;
}

.outer-mobile-image {
  position: relative;
  height: 700px;
  overflow: auto;
}

.img-fiscomed-mobile-top {
  background-image: url('./immagini/screen_fiscomed_mobile.webp');
  background-size: cover;
  height: 400px;
  position: absolute;
  width: 60vw;
  right: 0px;
  box-shadow: 2px 2px 10px rgb(217, 217, 217);
}

.img-fiscomed-mobile-bottom {
  background-image: url('./immagini/screen_fiscomed_mobile.webp');
  background-size: cover;
  background-position: right center;
  position: absolute;
  height: 400px;
  width: 60vw;
  left: 0px;
  top: 300px;
  box-shadow: 2px 2px 10px rgb(217, 217, 217);
}

.h6-landing {
  font-weight: 400;
  color: #373d3f;
  line-height: 30px;
}

.cuore-guida-mobile {
  position: absolute;
  width: 90px;
  margin-top: -50px;
  margin-left: 70px;
}

.like-guida-mobile {
  position: absolute;
  width: 90px;
  margin-top: -40px;
  left: 0px;
}

.header-codice-collega {
  background-color: #d0ffe1;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: -1;
}

.categorie-blog-mobile::-webkit-scrollbar {
  display: none;
}

.articolo-blog-desktop {
  font-weight: 400;
  color: #373d3f;
  line-height: 30px;
}

.articolo-blog-desktop > h1 {
  margin-bottom: 0;
}

.articolo-blog-desktop > h2 {
  margin-bottom: 0;
}

.articolo-blog-desktop > h3 {
  margin-bottom: 0;
}

.articolo-blog-desktop > p {
  margin-bottom: 0;
}

.cerchio-dichiarazione {
  border: 2px solid;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: inline-block;
  font-size: 30px;
  font-weight: 400;
  padding-top: 7px;
}

.progress-gialla {
  border-radius: 0.5rem;
}

.progress-gialla > .progress-bar {
  background-color: #f2b705;
}

.progress-blu {
  border-radius: 0.5rem;
}

.progress-blu > .progress-bar {
  background-color: #0583f2;
  border-radius: 0.5rem;
}

.contatto-rubrica:hover {
  background-color: #f7e7b7;
}

.span-check {
  left: -0.1em;
  position: absolute;
  text-align: center;
  width: 0.1em;
  line-height: inherit;
}

.consenso {
  font-size: 13px;
  color: #6c757d;
  line-height: 16px;
  text-align: left;
}

.sfondo-azzurro {
  background-color: #0583f20d;
}